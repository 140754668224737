.SectionsHeader{
    background-color: var(--color-header);
    /* width: 85%; */
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: .5rem;
    padding-bottom: 1rem;
    /* flex-wrap: wrap-reverse; */
}
.SectionsHeader--Shadow{
    visibility: hidden;
}
.SectionsHeader--Real{
    position: fixed;
    z-index: 100;
}

.SectionsHeader__Contents{
    width: 85%;
    display: flex;
    justify-content: center;
    /* padding-top: .5rem;
    padding-bottom: 1rem; */
    flex-wrap: wrap-reverse;
}

.SectionsHeader__Column{
    display: flex;
    align-items: center;
    justify-content: center;
}
.SectionsHeader__Column--Left{
    flex-basis: 25%;
}
.SectionsHeader__Column--Center{
    flex-basis: 50%;
    flex-wrap: wrap;
}
.SectionsHeader__Column--Right{
    flex-basis: 25%;
}
/* hide when small */
@media only screen and (max-width: 1500px) {
    .SectionsHeader__Column--Right{
        display: none;
    }
}

.SectionsHeader__Logo{
    height: 5rem;
    width: auto;
}

.SectionsHeader__Link{
    /* transform: perspective(70rem) rotateX(12deg); */
    transition: transform .5s, box-shadow .5s;
}
.SectionsHeader__Link:hover{
    transform: perspective(70rem) rotateY(-25deg);
}
.SectionsHeader__Link--Logo--Left:hover{
    transform: perspective(70rem) rotateY(25deg);
}
.SectionsHeader__Link > a{
    font-size: 2rem;
    color: var(--color-text-accent);
}
/* general smartphone media query */
@media only screen and (max-width: 575px) {
    .SectionsHeader__Link > a{
        font-size: 1rem;
        padding: 0rem .5rem;
    }
}
.SectionsHeader__Link > a:hover{
    color: var(--color-text);
}
.SectionsHeader__Spacer{
    padding: 0rem 1rem;
    font-size: 2rem;
    color: var(--color-text-accent);
}
/* general smartphone media query */
@media only screen and (max-width: 575px) {
    .SectionsHeader__Spacer{
        display: none;
    }
}
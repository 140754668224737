.PagesLibrary__Panels{
    width: 100%;
    display: flex;
}
.PagesLibrary__ListPanel, .PagesLibrary__ImagePanel{
    height: 80vh;
}

.PagesLibrary__ListPanel{
    width: 30%;
    display: flex;
    flex-direction: column;
    height: 80vh;
    overflow-y: scroll;
    margin: 2rem;
}
    .PagesLibrary__FilterLabel{
        color: var(--color-text-accent);
        margin-bottom: .5rem;
        font-size: 2rem;
    }
    .PagesLibrary__Filter{
        background-color: black;
        color: var(--color-text-accent);
        border: 1px solid var(--color-text-accent);
        border-radius: .1rem;
        margin-bottom: 1rem;
    }
    .PagesLibrary__CardLink{
        font-size: larger;
        margin-bottom: .5rem;
    }
    .PagesLibrary__CardLink--red{
        color: red;
    }
    .PagesLibrary__CardLink--orange{
        color: orange;
    }
    .PagesLibrary__CardLink--yellow{
        color: yellow;
    }
    .PagesLibrary__CardLink--violet{
        color: rgb(170, 57, 204);
    }
    .PagesLibrary__CardLink--wish_black{
        color: grey;
    }
    .PagesLibrary__CardLink--wish_white{
        color: white;
    }

.PagesLibrary__ImagePanel{
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
}
    .PagesLibrary__ImagePanel img{
        width: auto;
        height: 90%;
    }
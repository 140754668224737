.PagesHome{
    
}

.PagesHome__Image--Title{
    width: 100%;
    height: auto;
}
.PagesHome__Image--Card{
    margin: 1rem 0;
    width: 80%;
    height: auto;
    border-radius: .5rem;
    transform: perspective(70rem) rotateX(10deg);
    transition: transform .5s, box-shadow .5s;
}
.PagesHome__Image--Card:hover{
    transform: perspective(70rem) rotateX(0deg);
}
.PagesHome__BuyWidgetContainer{
    width: 50%;
    display: flex;
    flex-direction:column;
    align-items: center;
}
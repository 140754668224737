.PagesWizardRules{
    padding: 5rem;
}
.PagesWizardRules *{
    font-size: 2rem;
    line-height: 2rem;
}
.PagesWizardRules li{
    margin-bottom: 1rem;
}
.PagesWizardRules__Section{
    margin-bottom: 2rem;
}
.PagesWizardRules__Sublist{
    margin-left: 4rem;
}
.PagesBuy{
    /* display: flex; */
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.PagesBuy__Heading{
    text-align: center;
}
.PagesBuy__TextSection, .PagesBuy__TextSection a{
    font-size: 1.3rem;
}

.PagesBuy__Column{
    /* overflow: hidden; */
    padding: 3rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}
.PagesBuy__Column--Left{
    /* width: 50%; */
    flex-basis: 66%;;
}
.PagesBuy__Column--Right{
    /* width: 50%; */
    flex-basis: 33%;
}

.PagesBuy__BigImage{
    width: 100%;
    height: auto;
    border-radius: .25rem;
}
.PagesBuy__SmallImage{
    width: 100%;
    height: auto;
    border-radius: .25rem;
}
.PagesTheFirstGate__Pickers{
    display: flex;
}
.PagesTheFirstGate__Picker{
    display: flex;
    flex-direction: column;
}
.PagesTheFirstGate__Pickers img{
    width: 100%;
}
.PagesTheFirstGate__Pickers .ui.dropdown{
    background-color: black;
}
.PagesTheFirstGate__Pickers .ui.dropdown *{
    background-color: black;
}
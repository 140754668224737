.Layout__Root{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Layout__Page{
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3rem;
}
/* general smartphone media query */
@media only screen and (max-width: 575px) {
  .Layout__Page{
      width: 100%
  }
}
@font-face{
  font-family: "Grenze";
  src: url("../fonts/Grenze/Grenze-Regular.ttf");
}
@font-face{
  font-family: "Pirata One";
  src: url("../fonts/Pirata_One/PirataOne-Regular.ttf");
}

:root{
  /* --color-background-outer: #0f0f0f; */
  /* --color-background-outer: #724d08; */
  /* the color of the table: */
  /* --color-background-outer: #ad732b; */
  /* --color-background-outer: #917e15; */
  /* --color-background-outer: #5b248f; */
  /* the color of the violet card */
  /* --color-background-outer: #701d7f; */

  /* one of these 2 probably */

  /* darkened table color */
  --color-background-outer: #8f5f24;
  /* violet's cape */
  /* --color-background-outer: #471f57; */

  /* --color-background-outer-translucent: #0f0f0fDD; */
  /* --color-background-outer-translucent: #724d08DD; */
  --color-header: #0f0f0fDD;
  /* --color-background-inner: black; */
  --color-background-inner: #0f0f0f;
  --color-text: white;
  --color-text-accent: #ffd700;

  --color-official-orange: #ff5100;
  --color-official-violet: #501070;
  --color-official-yellow: #ffd700;
  --color-official-red: #f30316;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Grenze";
  color: var(--color-text);
}
* *{
  font-size: 1.2rem;
}

.Decoration__Heading{
  font-family: "Pirata One";
  font-size: 2rem;
  width: 85%;
  text-align: left;
  margin: 1rem 0;
}
.Decoration__Page{
  background-color: var(--color-background-inner);
  /* border-radius: .5rem; */
}


.Deocration__TextSection{
  width: 80%;
  margin: 2rem 0;
  text-align: left;
  /* Huge text, because the text is brief and to the point. */
  font-size: 1.7rem;
}
.Deocration__TextSection > * > li{
  font-size: 1.7rem;
}






/* fighting some specificity wars w/ semantic, lol */
button, .ui.button{
  background-color: var(--color-text-accent)!important;
  color:var(--color-background-outer)!important;
  border: none;
  border-radius: .25rem;
  padding: .6rem 1rem;
  margin: 1rem;
  transition: color .1s;
}
.ui.button .text, .ui.button .icon{
  color:var(--color-background-outer)!important;
  transition: color .1s;
}
button:hover, .ui.button:hover{
  background-color: var(--color-text-accent)!important;
  color: var(--color-text)!important;
  cursor: pointer;
}
.ui.button .text:hover{
  color:var(--color-text)!important;
}
.ui.button .menu{
  background-color: var(--color-text-accent)!important;
}
.ui.button .menu:hover{
  color: var(--color-text)!important;
}


a{
  color:var(--color-text-accent);
}
a:hover{
  color:var(--color-text-accent);
  text-decoration: underline;
}
body {
  background-color: var(--color-background-outer);
}
.SectionsFooter{
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-size: 1.3rem;
  line-height: 2rem;
  display: flex;
  flex-direction: column;
}
.SectionsFooter__Line{
  margin-top: 1rem;
  font-size: 1.3rem;
  line-height: 1.1rem;
}